<template>
  <div class="wrapper"></div>
</template>

<script>
import { ixmAppLogin, ixmAppAuthorize } from '../../api'
export default {
  components:{},
  props:{},
  data(){
    return {
    }
  },
  watch:{},
  computed:{},
  methods:{
      // 获取当前网页的协议+域名
    getPageBaseUrl() {
      var baseURL = window.location.origin + window.location.pathname;
      return baseURL;
    },
    ixmToken() {
      ixmAppAuthorize({
        url: encodeURIComponent(location.href)
      }).then(res=>{
        location.replace(res.data.data)
      })
    },
    ixmAuth(code) {
      ixmAppLogin({
        token: code,
        url:  encodeURIComponent(this.getPageBaseUrl() + '#' + this.$route.fullPath)
      }).then(res=>{
        var data = res.data.data;
        this.$store.state.newGift = data.newGift
        var token = data.tokenHead + " " + data.token;
        localStorage.setItem("token", token);
        localStorage.setItem("tokenHeader", data.tokenHeader);
        if (this.$route.query.path) {
            this.$router.replace({ path: this.$route.query.path,query:this.$route.query });
        } else {
            this.$router.replace({ path: "/home" });
        }
      })
    },
    // 获取连接参数正则
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    }
  },
  created(){
    if (this.getQueryString('code')||this.$route.query.code) {
      this.ixmAuth(this.getQueryString('code')||this.$route.query.code)
    } else {
      this.ixmToken()
    }
    // this.ixmAuth('gwq')
  },
  mounted(){
      
  }
}
</script>
<style scoped>
.wrapper{}
</style>